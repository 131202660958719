import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { readString } from 'react-papaparse';
import './Host.css';

const socket = io('https://triviagame-woe6.onrender.com');

const Host = () => {
    // State definitions
    const [questions, setQuestions] = useState([]);
    const [roomCode, setRoomCode] = useState(null);
    const [players, setPlayers] = useState({});
    const [scoreboard, setScoreboard] = useState({});
    const [timer, setTimer] = useState(10);
    const [gameStarted, setGameStarted] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [questionType, setQuestionType] = useState('multiple-choice');
    const [newQuestion, setNewQuestion] = useState('');
    const [options, setOptions] = useState(['', '', '', '']);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

    useEffect(() => {
        socket.on('playerJoined', (players) => setPlayers(players));
        socket.on('updateTimer', (timeLeft) => setTimer(timeLeft));
        socket.on('newQuestion', (data) => {
            setGameStarted(true);
            setCurrentQuestion(data.question);
            setTimer(data.timeLeft);
        });
        socket.on('hostScoreboardUpdate', (players) => setScoreboard(players));
        socket.on('gameOver', (finalScores) => {
            alert('Game over!');
            setScoreboard(finalScores);
        });

        return () => {
            socket.off('playerJoined');
            socket.off('updateTimer');
            socket.off('newQuestion');
            socket.off('hostScoreboardUpdate');
            socket.off('gameOver');
        };
    }, []);

    const createRoom = () => {
        const shuffledQuestions = [...questions].sort(() => 0.5 - Math.random());
        socket.emit('createRoom', { questions: shuffledQuestions });
        socket.on('roomCreated', ({ roomCode }) => setRoomCode(roomCode));
    };

    const startGame = () => socket.emit('startGame', roomCode);

    const addQuestion = () => {
        if (!newQuestion || (questionType === 'multiple-choice' && (!options.every(opt => opt) || !correctAnswer))) {
            alert('Please fill out all fields and select a correct answer.');
            return;
        }

        let questionToAdd = {};
        if (questionType === 'multiple-choice') {
            questionToAdd = { type: 'multiple-choice', question: newQuestion, options, correctAnswer };
        } else if (questionType === 'true-false') {
            questionToAdd = { type: 'true-false', question: newQuestion, options: ['True', 'False'], correctAnswer };
        }

        setQuestions([...questions, questionToAdd]);
        setNewQuestion('');
        setOptions(['', '', '', '']);
        setCorrectAnswer('');
    };

    const openPopup = (index) => {
        const selectedQuestion = questions[index];
        setNewQuestion(selectedQuestion.question);
        setCorrectAnswer(selectedQuestion.correctAnswer);
        setQuestionType(selectedQuestion.type);
        if (selectedQuestion.type === 'multiple-choice') {
            setOptions(selectedQuestion.options);
        }
        setSelectedQuestionIndex(index);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setNewQuestion('');
        setOptions(['', '', '', '']);
        setCorrectAnswer('');
        setSelectedQuestionIndex(null);
    };

    const saveQuestion = () => {
        const updatedQuestions = [...questions];
        updatedQuestions[selectedQuestionIndex] = {
            type: questionType,
            question: newQuestion,
            options: questionType === 'multiple-choice' ? options : ['True', 'False'],
            correctAnswer,
        };
        setQuestions(updatedQuestions);
        closePopup();
    };

    const removeQuestion = (index) => setQuestions(questions.filter((_, i) => i !== index));

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...options];
        updatedOptions[index] = value;
        setOptions(updatedOptions);
    };

    const handleCSVUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const result = readString(text, { header: false, skipEmptyLines: true });
            const csvQuestions = result.data.map((row) => {
                const [question, option1, option2, option3, option4, correctAnswer] = row;
                return { type: 'multiple-choice', question, options: [option1, option2, option3, option4], correctAnswer };
            });
            setQuestions([...questions, ...csvQuestions]);
        };
        reader.readAsText(file);
    };

    return (
        <div className="host-container">
            {!roomCode ? (
                <>
                    <h2>Host a Game</h2>
                    <div>
                        <h3>Import Questions from CSV</h3>
                        <input type="file" accept=".csv" onChange={handleCSVUpload} />
                    </div>
                    <div>
                        <h3>Add a Question</h3>
                        <label>
                            Select Question Type:
                            <select value={questionType} onChange={(e) => setQuestionType(e.target.value)}>
                                <option value="multiple-choice">Multiple Choice</option>
                                <option value="true-false">True/False</option>
                            </select>
                        </label>
                        <input type="text" placeholder="Enter question" value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />
                        {questionType === 'multiple-choice' ? (
                            <div>
                                {options.map((option, index) => (
                                    <div key={index}>
                                        <input type="text" placeholder={`Option ${index + 1}`} value={option} onChange={(e) => handleOptionChange(index, e.target.value)} />
                                        <input type="radio" name="correctAnswer" value={option} checked={correctAnswer === option} onChange={() => setCorrectAnswer(option)} />
                                        Correct
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>
                                <label>
                                    <input type="radio" name="correctAnswer" value="True" checked={correctAnswer === 'True'} onChange={() => setCorrectAnswer('True')} />
                                    True
                                </label>
                                <label>
                                    <input type="radio" name="correctAnswer" value="False" checked={correctAnswer === 'False'} onChange={() => setCorrectAnswer('False')} />
                                    False
                                </label>
                            </div>
                        )}
                        <button onClick={addQuestion}>Add Question</button>
                    </div>
                    <div>
                        <h3>Questions Added:</h3>
                        <div className="question-grid">
                            {questions.map((q, index) => (
                                <div key={index} className="question-card">
                                    <button onClick={() => removeQuestion(index)}>-</button>
                                    <div onClick={() => openPopup(index)}>
                                        {q.question} - Type: {q.type}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button onClick={createRoom}>Generate Room Code</button>
                </>
            ) : !gameStarted ? (
                <div>
                    <h3>Room Code: {roomCode}</h3>
                    <h4>Players Joined:</h4>
                    <ul>{Object.values(players).map((player, index) => <li key={index}>{player.name}</li>)}</ul>
                    <button onClick={startGame}>Start Game</button>
                </div>
            ) : (
                <div>
                    <div className="progress-bar-container">
                        <div className="progress-bar" style={{ width: `${(timer / 10) * 100}%`, backgroundColor: `rgb(${255 - (255 * (timer / 10))}, ${255 * (timer / 10)}, 0)` }}></div>
                    </div>
                    <h3>Question: {currentQuestion?.question}</h3>
                    <h4>Timer: {timer} seconds left</h4>
                    <h4>Scoreboard:</h4>
                    <ul>{Object.values(scoreboard).sort((a, b) => b.score - a.score).map((player, index) => <li key={index}>{player.name}: {player.score} pts</li>)}</ul>
                </div>
            )}
            {isPopupOpen && (
                <div className="popup">
                    <h3>Edit Question</h3>
                    <input type="text" placeholder="Enter question" value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />
                    {questionType === 'multiple-choice' ? (
                        <div>{options.map((option, index) => (
                            <div key={index}>
                                <input type="text" placeholder={`Option ${index + 1}`} value={option} onChange={(e) => handleOptionChange(index, e.target.value)} />
                                <input type="radio" name="correctAnswer" value={option} checked={correctAnswer === option} onChange={() => setCorrectAnswer(option)} />
                                Correct
                            </div>
                        ))}</div>
                    ) : (
                        <div>
                            <label>
                                <input type="radio" name="correctAnswer" value="True" checked={correctAnswer === 'True'} onChange={() => setCorrectAnswer('True')} />
                                True
                            </label>
                            <label>
                                <input type="radio" name="correctAnswer" value="False" checked={correctAnswer === 'False'} onChange={() => setCorrectAnswer('False')} />
                                False
                            </label>
                        </div>
                    )}
                    <button onClick={saveQuestion}>Save</button>
                    <button onClick={closePopup}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default Host;
