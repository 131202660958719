import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Host from './components/Host';
import Player from './components/Player';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/host" element={<Host />} />
                <Route path="/player" element={<Player />} />
                <Route path="/" element={
                    <div>
                        <h1>Welcome to Trivia Game</h1>
                        <p>Select Host or Player to start</p>
                    </div>
                } />
            </Routes>
        </Router>
    );
}

export default App;
